import PropTypes from 'prop-types';
import classNames from "classnames";
import { markdownToHtml } from "../markdown/markdownToHtml";

import styles from './styles/Markdown.module.scss';
import {useContext, useMemo} from "react";
import {StoreContext} from "../../stores/StoreLoader";
import {markdownDefaultMaxLength} from "../editors/MarkdownEditor";

export default function Markdown({withStyles = false, source, allowLinks = true, ...restProps}) {
    const {interfaceStore} = useContext(StoreContext);
    const markdownClassname = classNames({
        [styles.markdown]: withStyles === true,
    });

    const contents = useMemo(() => {
        let c = source || "";
        if (!allowLinks) {
            c = c?.replace(/\[.*\]\(.*\)/g, "") || "";
        }

        if (c.length > markdownDefaultMaxLength) {
            c = c.slice(0, markdownDefaultMaxLength);
        }

        return markdownToHtml(c, {currentFullUrl: interfaceStore.currentFullUrl, allowGfmLinks: allowLinks});
    }, [])

    return <div className={markdownClassname} {...restProps} dangerouslySetInnerHTML={{__html: contents}} />
}

Markdown.propTypes = {
    withStyles: PropTypes.bool,
    source: PropTypes.string,
    allowLinks: PropTypes.bool,
};

